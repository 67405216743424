<template>
    <div class="main">
        <h1>Mentions légales</h1>        
        
        <p>Il est rappelé que le secret des correspondances n’est pas garanti sur le réseau Internet et qu’il appartient à chaque utilisateur d’Internet de prendre toutes les mesures appropriées de façon à protéger ses propres données et/ou logiciels de la contamination d’éventuels virus circulant sur Internet.</p>
    
        <h2>I.ÉDITEUR DU SITE</h2>
        <p>
            L’adresse de notre site web est : <a href="https://www.mindfulnesspilates.fr">https://www.mindfulnesspilates.fr</a><br />  
            Siret 898 772 652 00027 / code APE : 8551Z, domiciliation située RD 31 Route du Mas-Blanc 13210, Saint-Rémy-De-Provence, France. <br /> 
            Email : <a href="mailto:contact@mindfulnesspilates.fr">contact@mindfulnesspilates.fr</a>
        </p>
        <p>Les coordonnées exactes de l’éditeur ont été transmises à l’hébergeur.</p>

        <h2>II.HÉBERGEUR </h2>
        <p>
            OVH SAS<br />
            2 rue Kellermann<br />
            59100 Roubaix, France<br />
            Téléphone : +33 9 72 10 10 07
        </p>

        <h2>III.RÉALISATION </h2>
        <p>
            <a href="https://synthes3dweb.com/" target="_blank">SYNTHES'3D WEB</a><br />
            1 rue Mahatma Gandhi<br />
            Le Décisium – Bât C1<br />
            13090 Aix-en-Provence – France<br />
            Téléphone : + 33 4 86 31 53 84

        </p>

        <h2>IV. RÈGLES DE CONFIDENTIALITÉ</h2>
        
        <h3>Introduction</h3>
        <p>
            Mindfulness Pilates Studio veille à garantir la protection de votre vie privée lorsque vous utilisez nos services. À cet effet, nous disposons de règles expliquant comment vos données personnelles seront traitées et protégées. Veuillez-vous tenir informé(e) de toute modification apportée à ces règles de confidentialité en visitant notre site internet.<br />
            Les présentes Règles de Confidentialité concernent uniquement les clients de Mindfulness Pilates Studio et les utilisateurs de nos services en ligne.
        </p>
        <p>
            En France et dans l’Union Européenne, les données personnelles sont protégées notamment par les textes suivants : Loi du 6 janvier 1978, article 226-13 du Code Pénal, Directive européenne du 24 octobre 1995.<br />
            Vous pouvez trouver la Loi relative à l’informatique, aux fichiers et aux libertés sur le site de la Commission Nationale de l’Informatique et des Libertés, à l’adresse suivante : <a href="https://www.cnil.fr" target="_blank">https://www.cnil.fr</a> . 
        </p>
        <p>
            Conformément à la loi Informatique et Libertés du 6 janvier 1978, une déclaration a été faite auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL) sous le numéro 2176185 et respecte les dispositions de la loi 78-17 du 6 Janvier 1978 relative à l’informatique, aux fichiers et aux libertés.<br />
            Mindfulness Pilates Studio s’engage à respecter le caractère confidentiel des informations personnelles fournies par les clients et visiteurs du site. Vous trouverez dans le paragraphe « Quels types de données personnelles recueillons-nous ? » le type d’informations que Mindfulness Pilates Studio est en mesure de recueillir lorsque vous visitez son site, ainsi que la manière dont elles seront utilisées ultérieurement.
        </p>

        <h3>Qui est responsable de vos données personnelles ?</h3>
        <p>
            Mindfulness Pilates Studio contrôle les données personnelles que vous lui soumettez et en est responsable conformément à la loi Française de protection des données personnelles (référence de la loi) et à la Directive européenne 95/46/CE relative à la vie privée. Mindfulness Pilates Studio est le gestionnaire des données personnelles.
        </p>

        <h3>Où vos données personnelles sont-elles stockées ?</h3>
        <p>
            Les données vous concernant que nous recueillons sont stockées sur un serveur en France. 
        </p>

        <h3>Quels types de données personnelles recueillons-nous ?</h3>
        <p>
            Nous collectons les données personnelles que vous nous transmettez, par exemple, lorsque vous réservez un cours, contactez notre service client via « contact », vous vous inscrivez à la newsletter. Les données personnelles que vous pourriez nous transmettre incluent, entre autres, vos coordonnées et vos informations de paiement. Par ailleurs, nous pourrions collecter certaines données personnelles auprès de sources externes, telles que vos changements d’adresse. 
        </p>

        <h3>Comment utilisons-nous vos données personnelles ?</h3>
        <p>
            Nous avons besoin de vos données personnelles aux fins suivantes :<br />
            – Créer et gérer vos réservations de cours à domicile et/ou en ligne chez Mindfulness Pilates Studio ;<br />
            – Traiter vos réservations et/ou annulations via nos services en ligne ;<br />
            – Envoyer des notifications de statut de réservations de cours par courriel ;<br />
            – Vous contacter en cas de problème de réservations / horaires ;<br />
            – Répondre à vos questions et vous informer des services nouveaux ou modifiés ;<br />
            – Envoyer nos newsletters ;<br />
            – Gérer vos réservations en effectuant des vérifications de crédit ;<br />
            – Vérifier que vous avez l’âge légal pour effectuer des paiements en ligne ;<br />
            – Vous envoyer des enquêtes pour vous offrir la possibilité d’influencer notre programme et nos services ;<br />
            – Tester et améliorer nos systèmes par le biais desquels sont fournis nos services ;<br />
            – Éviter toute utilisation frauduleuse ou incorrecte de nos services.
        </p>
        <p>
            Nous ne conservons vos données que le temps nécessaire aux fins précitées ou dans les limites prescrites par la loi. À l’issue de ce délai, vos données personnelles seront supprimées.<br />
            Conformément aux dispositions de l’article 223-2 du Code de la consommation, le client est informé de son droit à s’inscrire sur la liste d’opposition au démarchage téléphonique via le site internet <a href="http://www.bloctel.gouv.fr" target="_blank">http://www.bloctel.gouv.fr</a>. 
        </p>

        <h3>Quels sont vos droits ?</h3>
        <p>
            Vous avez le droit de demander des informations relatives aux données personnelles que nous détenons vous concernant. Ladite information porte notamment sur la base juridique du traitement. Si vos données sont inexactes, incomplètes ou non pertinentes, vous pouvez demander la correction ou le retrait des informations. Nous ne pouvons supprimer vos données en cas d’obligation de conservation légale, par exemple si elle est prescrite par le droit comptable ou lorsqu’il existe un motif juridique pour conserver les données, un débit non crédité par exemple. Vous pouvez retirer votre consentement nous autorisant à utiliser vos données à des fins de marketing à tout moment. Vous pouvez nous contacter en envoyant un courriel à l’adresse suivante : <a href="mailto:contact@mindfulnesspilates.fr" target="_blank">contact@mindfulnesspilates.fr</a> . Vous disposez en outre du droit de vous opposer au traitement, de solliciter une limitation du traitement ainsi que du droit à la portabilité des données.
        </p>
        <p>
            <u>A titre informatif, voici un rappel des articles de loi sur vos droits :</u><br />
            En application de la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, et au règlement général sur la protection des données de l’UE (en vigueur depuis le 24 mai 2016), vous disposez d’un droit d’accès (article 15 EU-RGPD), d’un droit de suppression (article 17 EU-RGPD), d’un droit de rectification (article 16 EU-RGPD), d’un droit de restriction du traitement des données (article 18 EU-RGPD), d’un droit à la portabilité des données (article 20 EU-RGPD), d’un droit d’opposition auprès d’une autorité de surveillance de la protection des données (article 77 EU-RGPD), d’un droit de rétractation d’un consentement (article 7, alinéa 3 EU-RGPD) ainsi qu’un droit d’opposition aux traitements des données (article 21 EU-RGPD) sur des données vous concernant.
        </p>
        <p>
            (Les articles du règlement général sur la protection des données (EU-RGPD) sont consultables sur le site <a href="http://www.privacy-regulation.eu" target="_blank">http://www.privacy-regulation.eu</a> .
        </p>

        <h3>Qui a accès à vos données personnelles ?</h3>
        <p>Toutes les informations de vos demandes ne sont utilisées que dans le cadre de vos réservations de cours de pilates avec <a href="https://www.mindfulnesspilates.fr">www.mindfulnesspilates.fr</a> . Vos données pourront être transmises à Mindfulness Pilates Studio. Ces informations ne sont jamais partagées avec des tiers ou revendues. Toutefois, les données transmises aux tiers ne seront utilisées qu’aux fins d’améliorer les services précités, par exemple aux instructeurs dans le cadre des cours à domicile et/ou en ligne. Enfin, vos informations bancaires ne sont jamais en notre possession. Les transactions sont entièrement traitées par les modules de paiement sécurisé de nos partenaires Lydia ou Sumup, consultables sur les sites : <a href="https://www.lydia-app.com/" target="_blank">https://www.lydia-app.com/</a> - <a href="https://sumup.fr" target="_blank">https://sumup.fr</a> .</p>
    
        <h3>Comment protégeons-nous vos données personnelles ?</h3>
        <p>Nous avons pris des mesures techniques et organisationnelles pour protéger vos données contre la perte, la manipulation, l’accès non autorisé. Nous adaptons constamment nos mesures de sécurité en suivant les progrès et développements technologiques. Pour sécuriser au maximum vos achats par carte chez nous, toutes les informations sont envoyées sous forme cryptée.</p>
    
        <h2>V. COOKIES</h2>

        <p>
            Le site utilise des cookies (témoins de connexion) ce dont l’utilisateur est informé en arrivant sur le site internet qui permettent d’enregistrer des informations relatives à la navigation de l’ordinateur sur le site internet. Ces cookies ne sont installés qu’après acceptation par l’utilisateur, la poursuite de la navigation sur le site internet valant acceptation. L’utilisateur peut s’opposer à l’utilisation de ces cookies en paramétrant son navigateur, sachant que l’accès à certains services peut nécessiter l’acceptation préalable par l’utilisateur des cookies.
        </p>
        <p>
            Mindfulness Pilates Studio utilise des cookies pour que votre visite soit plus simple et agréable. Nous n’utilisons pas les cookies pour conserver des informations personnelles ni pour divulguer des informations à des tiers.<br />
            Il existe deux types de cookies : permanents et temporaires (cookies de session). Les cookies permanents sont stockés sous forme de fichier sur votre ordinateur ou votre appareil mobile pendant une durée ne dépassant pas 12 mois. Les cookies de session sont stockés de manière temporaire et disparaissent dès la fermeture de votre session de navigation. Nous utilisons des cookies permanents pour conserver votre choix de page d’accueil ainsi que vos données personnelles si vous cochez « Se souvenir de moi » quand vous vous connectez.<br />
            Nous utilisons des cookies de session lorsque vous avez recours à la fonction de filtre pour consulter les programmes et pour vérifier si vous êtes connecté ou si vous avez réservé un cours. Vous pouvez facilement effacer les cookies de votre ordinateur ou de votre appareil mobile à l’aide de votre navigateur. 
        </p>
        <p>
            Pour consulter les instructions relatives à la gestion et à la suppression des cookies, merci de vous reporter à la rubrique d’aide de votre navigateur. Selon vos préférences, vous pouvez désactiver les cookies ou demander à être averti à chaque fois qu’un nouveau cookie est transmis à votre ordinateur ou votre appareil mobile. Veuillez noter que si vous désactivez les cookies, vous ne pourrez plus profiter de toutes nos fonctionnalités.
        </p>

        <h3>Cookies tiers</h3>
        <p>
            Nous utilisons des cookies tiers afin de recueillir des statistiques sous forme agrégée dans des outils d’analyse tels que Google Analytics et Optimizely. Nous utilisons l’outil Google Analytics Advertising. Pour en savoir plus et savoir comment vous désinscrire, cliquez ici : <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank">https://tools.google.com/dlpage/gaoptout/</a> . Ces cookies peuvent être permanents ou temporaires (cookies de session). Les cookies permanents sont stockés sur votre ordinateur ou votre appareil mobile pendant une durée ne dépassant pas 24 mois.
        </p>

        <h2>CONTACT</h2>
        <p>
            Téléphone : +33 6 33 56 24 89 (du lundi au vendredi, 7h-20h)<br />
            Service clients : <a href="mailto:contact@mindfulnesspilates.fr">contact@mindfulnesspilates.fr</a><br />
            Direction de la publication : Mindfulness Pilates Studio
        </p>
    </div>
</template>

<script>
export default {
    name : "LegalNotices"
}
</script>

<style scoped lang="scss">
@import "./../scss/variables.scss";

.main {
    margin-top:150px;
    margin-bottom:80px;
}

h1 {
    font-size:60px;
    text-align: center;
    margin-bottom:40px;
}

h2 {
    font-size:25px;
    margin-top:40px;
    margin-bottom: 20px;
}

h3 {
    margin-bottom:20px;
    margin-top:10px;
}

p {
    text-align: justify;
    margin-bottom:20px;
}

a {
    text-decoration: none;
    color:$mainColor;
}

a:hover {
    text-decoration: underline;
}
</style>